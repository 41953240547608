'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import { Link, withRouter } from "react-router-dom";
import superagent from 'superagent';
import PlacesTable from '../places_table.jsx';
import PlaceCharts from '../place_charts.jsx';
import * as utils from '../utils.jsx';

class PlaceView extends React.Component {
	state = {
		data: null,
		error: null,
	}

	componentDidMount() {
		this.fetchData();
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props == prevProps) return;
		if (this.state.data || this.state.error) {
			this.setState({...this.state, data: null, error: null});
		}
		this.fetchData();
	}

	fetchData() {
		superagent
			.get('/api/place')
			.query({ placeid: this.props.placeid, type: this.props.type })
			.then((result) => {
				this.receiveData(result.body);
			})
			.catch((err) => {
				if (err.status == 404) {
					this.setState({...this.state, error: "Place not found"});
				} else {
					this.setState({...this.state, error: "API error"});
				}
			});
	}
	receiveData(data) {
		this.setState({...this.state, data: data});
	}

	render() {
		if (this.state.error) return <div style={{textAlign: 'center'}}>Error: {this.state.error}</div>;
		if (!this.state.data) return <div style={{textAlign: 'center'}}>Loading data...</div>;

		return <div>
			{utils.hasData(this.state.data.data) ? <PlaceCharts place={this.state.data.place} data={this.state.data.data} /> : null}
			{this.state.data.children.map((item) => <PlacesTable key={item.type} title={item.type} places={item.places} />)}
		</div>;
     }
}
export default withRouter(PlaceView);
