'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
//import HomeView from './views/home.jsx';
//import AllStatesView from './views/all_states.jsx';
import PlaceView from './views/place.jsx';

export default function App(props) {
	return (
		<Router>
			<div>
				<nav>
					<ul>
						<li>
							<Link to="/">Home</Link>
						</li>
						<li>
							<Link to="/metros">US Metro Areas</Link>
						</li>
						{/*
						<li>
							<Link to="/all-states">All States View</Link>
						</li>
						<li>
							<Link to="/hotspots">Hotspots</Link>
						</li>
						<li>
							<Link to="/countermeasures">Countermeasures</Link>
						</li>
						*/}
					</ul>
				</nav>

				{/* A <Switch> looks through its children <Route>s and
				renders the first one that matches the current URL. */}
				<Switch>
					{/*<Route path="/all-states" component={AllStatesView} />*/}
					<Route path="/places/:place+" component={PlaceRoute} />
					<Route path="/metros" component={MetrosRoute} />
					<Route path="/" component={HomeRoute} />
				</Switch>
			</div>
	</Router>
	);
};

function PlaceRoute(props) {
	let { place } = useParams();
	return <PlaceView placeid={place} />;
}
function MetrosRoute(props) {
	return <PlaceView placeid={null} type="metro" />;
}
function HomeRoute(props) {
	return <PlaceView placeid="us" />;
}
