'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import { Link, withRouter } from "react-router-dom";
import MUIDataTable from "mui-datatables";

let fieldToID = null;
function getRowAnd(func) {
	return function(value, tableMeta, updateValue) {
		const row = Object.fromEntries(Object.entries(fieldToID).map((args) => [args[0], tableMeta.rowData[args[1]]]));
		return func(row);
	}
}
function numeric(value, tableMeta, updateValue) {
	return value != null && value.toLocaleString ? value.toLocaleString('en-US') : value;
}

function PlacesTable(props) {
	const options = {
		selectableRows: "none",
		rowsPerPage: 100,
		rowsPerPageOptions: [25, 50, 100, 1000],
	};

	const cols = [
		{
			name: 'placeid',
			label: 'Place ID',
			options: { display: 'excluded' },
		},
		{
			name: 'name',
			label: 'Name',
			options: { customBodyRender: getRowAnd((row) => <Link to={"/places/" + row.placeid}>{row.name}</Link>) },
		},
		{
			name: 'population',
			label: 'Population',
			options: {
				customBodyRender: numeric,
				sortDirection: 'desc',
			},
		},
		{
			name: 'tests',
			label: 'Tests',
			options: {
				display: 'false',
				customBodyRender: numeric,
			},
		},
		{
			name: 'cases',
			label: 'Cases',
			options: {
				customBodyRender: numeric,
			},
		},
		{
			name: 'cases_new_today',
			label: 'New Cases',
			options: {
				customBodyRender: (value) => value && value > 0 ? <div style={{color: 'black', backgroundColor: 'orange', height: '1.5em', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>+{value.toLocaleString('en-US')}</div> : '',
			},
		},
		{
			name: 'weekly_cases',
			label: 'Weekly New Cases',
			options: {
				customBodyRender: (value) => value && value > 0 ? <div style={{color: 'black', backgroundColor: 'orange', height: '1.5em', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>+{value.toLocaleString('en-US')}</div> : '',
			},
		},
		{
			name: 'weekly_cases_per_pop',
			label: 'Weekly New Cases/1M',
			options: {
				display: 'false',
				customBodyRender: (value) => value && value > 0 ? <div style={{color: 'black', backgroundColor: 'orange', height: '1.5em', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>+{value.toLocaleString('en-US')}</div> : '',
			},
		},
		{
			name: 'hosp',
			label: 'Hospitalizations',
			options: {
				display: 'false',
				customBodyRender: numeric,
			},
		},
		{
			name: 'hosp_new_today',
			label: 'New Hosp',
			options: {
				display: 'false',
				customBodyRender: (value) => value && value > 0 ? <div style={{color: 'white', backgroundColor: 'blue', height: '1.5em', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>+{value.toLocaleString('en-US')}</div> : '',
			},
		},
		{
			name: 'hosp_7day_chg',
			label: 'Hosp Growth 7day',
			options: {
				display: 'false',
				customBodyRender: (value) => value && value > 0 ? <div style={{color: 'white', backgroundColor: 'blue', height: '1.5em', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>+{value.toLocaleString('en-US')}</div> : '',
			},
		},
		{
			name: 'in_hosp',
			label: 'In Hospital',
			options: {
				display: 'false',
				customBodyRender: numeric,
			},
		},
		{
			name: 'in_hosp_per_pop',
			label: 'In Hospital/1M',
			options: {
				display: 'false',
				customBodyRender: numeric,
			},
		},
		{
			name: 'in_hosp_7day_chg',
			label: 'In Hosp 7day Chg',
			options: {
				display: 'false',
				customBodyRender: (value) => value && value > 0 ? <div style={{color: 'white', backgroundColor: 'blue', height: '1.5em', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>+{value.toLocaleString('en-US')}</div> : '',
			},
		},
		{
			name: 'deaths',
			label: 'Deaths',
			options: {
				customBodyRender: numeric,
			},
		},
		{
			name: 'deaths_new_today',
			label: 'Deaths Today',
			options: {
				customBodyRender: (value) => value && value > 0 ? <div style={{color: 'white', backgroundColor: 'red', height: '1.5em', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>+{value.toLocaleString('en-US')}</div> : '',
			},
		},
		{
			name: 'weekly_deaths',
			label: 'Weekly Deaths',
			options: {
				customBodyRender: (value) => value && value > 0 ? <div style={{color: 'white', backgroundColor: 'red', height: '1.5em', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>+{value.toLocaleString('en-US')}</div> : '',
			},
		},
		{
			name: 'weekly_deaths_per_pop',
			label: 'Weekly Deaths/1M',
			options: {
				display: 'false',
				customBodyRender: (value) => value && value > 0 ? <div style={{color: 'white', backgroundColor: 'red', height: '1.5em', textAlign: 'center', padding: '5px', fontWeight: 'bold'}}>+{value.toLocaleString('en-US')}</div> : '',
			},
		},
		{
			name: 'tests_per_day',
			label: 'Tests/Day',
			options: {
				display: 'false',
				customBodyRender: numeric,
			},
		},
		{
			name: 'tests_per_day_per_pop',
			label: 'Tests/Day/1M',
			options: {
				display: 'false',
				customBodyRender: numeric,
			},
		},
		{
			name: 'tests_per_pop',
			label: 'Tests/1M',
			options: {
				display: 'false',
				customBodyRender: numeric,
			},
		},
		{
			name: 'cases_per_pop',
			label: 'Cases/1M',
			options: {
				display: 'false',
				customBodyRender: numeric,
			},
		},
		{
			name: 'hosp_per_pop',
			label: 'Hosp/1M',
			options: {
				display: 'false',
				customBodyRender: numeric,
			},
		},
		{
			name: 'deaths_per_pop',
			label: 'Deaths/1M',
			options: {
				customBodyRender: numeric,
			},
		},
		{
			name: 'pos_pct',
			label: 'Positive %',
			options: {
				display: 'false',
				customBodyRender: (value) => value == null ? value : value + "%",
			 },
		},
		{
			name: 'pos_pct_7day_chg',
			label: 'Pos % Weekly Chg',
			options: {
				display: 'false',
				customBodyRender: (value) => value == null ? value : value + "%",
			 },
		},
		{
			name: 'tests_per_death',
			label: 'Tests/Death',
			options: {
				display: 'false',
				customBodyRender: numeric,
			},
		},
		{
			name: 'crude_cfr',
			label: 'cCFR',
			options: {
				display: 'false',
				customBodyRender: (value, tableMeta, updateValue) => value ? value.toFixed(2) : null,
				hint: 'Crude Case Fatality Rate (Cases/Deaths)',
			 }
		},
	];
	let tableCols = cols.map((col) => ({ label: col.label, options: col.options }));
	fieldToID = Object.fromEntries(cols.map((col, idx) => [col.name, idx]));
	function dataToRows(rows) {
		return rows.map((row) => cols.map((col, idx) => row[col.name]));
	}
	const rows = dataToRows(props.places);

	return <MUIDataTable title={props.title} columns={cols} data={rows} options={options} />;
}
export default withRouter(PlacesTable);
