'use strict';

import React from 'react';
import ReactDOM from 'react-dom';

import Chart from './chart.jsx';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default function PlaceCharts(props) {
	const have_data_for = (key) => props.data.hasOwnProperty(key) ? props.data[key].some((item) => item !== null) : false;
	const chart = (key) => props.data.hasOwnProperty(key) ? props.data[key] : [];

	/*
	for (var item of props.data) {
		if (item.tests_total_3day_avg_chg && item.tests_total_3day_avg_chg > item.tests_pos_3day_avg_chg) {
			pos_rate.push([item.date, Math.round((item.tests_pos_3day_avg_chg / item.tests_total_3day_avg_chg) * 100)]);
		} else {
			pos_rate.push([item.date, null])
		}
		if (item.tests_pos) {
			ccfr.push([item.date, ((item.deaths / item.tests_pos) * 100).toFixed(1)]);
		} else {
			ccfr.push([item.date, null])
		}
	}
	*/

	return <div>
		<div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><h1>{props.place.fullname}</h1></div>
		<div style={{display: 'flex', width: '100%', height: '250px'}}>
			<div style={{width: '30%', marginLeft: '5%', marginRight: '2%' }}><Chart syncID="sync" data={chart('deaths')} label="Deaths" lineColor="red" /></div>
			<div style={{width: '30%', marginLeft: '2%', marginRight: '2%' }}><Chart syncID="sync" data={chart('deaths_daily_chg')} label="New Deaths" lineColor="red" /></div>
			<div style={{width: '30%', marginLeft: '2%', marginRight: '5%' }}><Chart syncID="sync" data={chart('deaths_7day_avg_chg')} label="New Deaths (7day avg)" lineColor="red" /></div>
		</div>
		<div style={{display: 'flex', width: '100%', height: '250px'}}>
			<div style={{width: '30%', marginLeft: '5%', marginRight: '2%' }}><Chart syncID="sync" data={chart('tests_pos')} label="Cases" lineColor="blue" /></div>
			<div style={{width: '30%', marginLeft: '2%', marginRight: '2%' }}><Chart syncID="sync" data={chart('tests_pos_daily_chg')} label="New Cases" lineColor="blue" /></div>
			<div style={{width: '30%', marginLeft: '2%', marginRight: '5%' }}><Chart syncID="sync" data={chart('tests_pos_7day_avg_chg')} label="New Cases (7day avg)" lineColor="blue" /></div>
		</div>
		{have_data_for('total_hosp') ?
		<div style={{display: 'flex', width: '100%', height: '250px'}}>
			<div style={{width: '30%', marginLeft: '5%', marginRight: '2%' }}><Chart syncID="sync" data={chart('total_hosp')} label="Hospitalizations" lineColor="orange" yMin={0} /></div>
			<div style={{width: '30%', marginLeft: '2%', marginRight: '2%' }}><Chart syncID="sync" data={chart('total_hosp_daily_chg')} label="New Hospitalizations" lineColor="orange" yMin={0} /></div>
			<div style={{width: '30%', marginLeft: '2%', marginRight: '5%' }}><Chart syncID="sync" data={chart('total_hosp_7day_avg_chg')} label="New Hospitalizations (7day avg)" lineColor="orange" yMin={0} /></div>
		</div> : null}
		<div style={{display: 'flex', width: '100%', height: '250px'}}>
			<div style={{width: '30%', marginLeft: '5%', marginRight: '2%' }}><Chart syncID="sync" data={chart('tests_total')} label="Total Tests Performed" lineColor="green" /></div>
			<div style={{width: '30%', marginLeft: '2%', marginRight: '2%' }}><Chart syncID="sync" data={chart('tests_total_daily_chg')} label="Daily Tests" lineColor="green" /></div>
			<div style={{width: '30%', marginLeft: '2%', marginRight: '5%' }}><Chart syncID="sync" data={chart('tests_total_7day_avg_chg')} label="Daily Tests (7day avg)" lineColor="green" /></div>
		</div>
		<div style={{display: 'flex', width: '100%', height: '250px'}}>
			<div style={{width: '40%', marginLeft: '5%', marginRight: '5%' }}><Chart syncID="sync" data={chart('pos_rate_7day')} label="% Positive (7day avg)" lineColor="purple" yMin={0} yMax={100} /></div>
			{have_data_for('in_hosp') ?
				<div style={{width: '40%', marginLeft: '5%', marginRight: '5%' }}><Chart syncID="sync" data={chart('in_hosp')} label="In Hospital" lineColor="orange" yMin={0} /></div>
			: null}
			{/*<div style={{width: '45%', marginLeft: '2%', marginRight: '5%' }}><Chart data={ccfr} label="Crude CFR" lineColor="orange" yMin={0} /></div>*/}
		</div>
	</div>;
}
