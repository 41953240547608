'use strict';

import React from 'react';
import ReactDOM from 'react-dom';

import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ReferenceLine, Line } from 'recharts';

function Chart(props) {
	let events = [];
	if (props.events) {
		events = Object.entries(props.events).map(function(item) {
			let [ key, evt ] = item;
			return <ReferenceLine key={key} y={evt.position} label={evt.label} stroke="blue" strokeDasharray="3 3" />;
		})
	}

	let data = props.data.map((item) => ({x: item[0], y: item[1]}))
	let yMin = 'yMin' in props ? props.yMin : 'auto';
	let yMax = 'yMax' in props ? props.yMax : 'auto';
	return <ResponsiveContainer width="100%" height="100%">
		<LineChart data={data} syncId={props.syncID}>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis dataKey="x" />
			<YAxis scale={props.logScale ? 'log' : 'auto'} domain={[yMin, yMax]} />
			{ events }
			<Tooltip isAnimationActive={false} />
			<Legend />
			<Line type="monotone" dataKey="y" name={props.label} stroke={props.lineColor} strokeWidth={2} dot={false} animationDuration={500} />
		</LineChart>
	</ResponsiveContainer>;
}
export default React.memo(Chart);
